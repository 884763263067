export default function applyPollyfils() {
    if (window.BroadcastChannel == null) {
        window.BroadcastChannel = BroadcastChannelPollyfill;
    }

    if (window.crypto.randomUUID == null) {
        window.crypto.randomUUID = randomUUID;
    }
}

class BroadcastChannelPollyfill implements BroadcastChannel {
    name = 'UNSUPPORTED_BROWSER'
    addEventListener() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }

    close() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }

    dispatchEvent() {
        console['warn']('BroadcastChannel is not supported on this browser')
        return false;
    }

    onmessage() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }
    onmessageerror() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }
    postMessage() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }
    removeEventListener() {
        console['warn']('BroadcastChannel is not supported on this browser')
    }
}

function randomUUID()  {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    }) as `${string}-${string}-${string}-${string}-${string}`;
}


applyPollyfils();